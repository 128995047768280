import {Component, Prop, Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import {TProductBasket} from '@/types/TProductBasket';
import user from '@/store/user';
import {getModule} from "vuex-module-decorators";
import {TAdresaLivrare} from "@/types/TAdresaLivrare";
import {TOfferHeader} from "@/types/TOfferHeader";
import {timeUntilFutureDate} from '@/modules/utils'
import nomenclatoare from "@/store/nomenclatoare";
import {ServiceCerere} from '@/services/ServiceCerere';
import {CONFIG_ENV} from "@/config";
import {ServiceFavorites} from "@/services/ServiceFavorites";
import favorites from "@/store/favorites";
import {ServiceAdreseLivrare} from "@/services/ServiceAdreseLivrare";
import {ServiceOffer} from "@/services/ServiceOffer";
import eventbus from "@/store/eventbus";
import EditQuantityArticleCerere from '@/components/EditQuantityArticleCerere/EditQuantityArticleCerere.vue';
import BrowseCategories from '@/pages/BrowseCategories/BrowseCategories.vue';
import BrowseArticles from '@/pages/BrowseArticles/BrowseArticles.vue';
import NomEditAdresaLivrare from '@/components/NomEditAdresaLivrare/NomEditAdresaLivrare.vue';
import { TPossibleOfferStatus } from '@/types/TPossibleOfferStatus';
import { ServiceDownload } from '@/services/ServiceDownload';
import { ServiceMail } from '@/services/ServiceMail';


type TProductBasketMarkedFavorites = TProductBasket & {isInFavorite:boolean};

@Options({
    name: "Cerere",
    components: {BrowseCategories, BrowseArticles, EditQuantityArticleCerere, NomEditAdresaLivrare}
})
export default class Cerere extends Vue {
    @Prop({ default: null }) public readonly propOfferId!: string;
    @Prop() public closeHandler!: (needRefresh: boolean)=>void;
    @Prop({ default: false }) public readonly fromBackOffice!: boolean;
    public termenCerere='';
    public nrComandaCerere='';
    public nrComandaCerereInitial='';
    public slidAdresaLivrare='';
    public isEditingAsNewRequest=false;
    public showBrowseArticles = false;
    public myLocale=CONFIG_ENV.myLocale;
    public pidForBrowseCategory = '0';
    public productPid = '';
    public code = '';
    public pidForBrowseCategoryHasArticles = false;
    public adrese:TAdresaLivrare[]=[];
    public offerHeader:TOfferHeader={
        offerId: '',
        offerSlid: '',
        status: '',
        trackCreationDate: '',
        trackDateOfertare: '',
        trackDateAccept: '',
        dateWhenOfferExpire:'',
        termenLivrareSolicitat:'',
        qKg:null,
        valFinalaFaraTvaRON:null,
        valTvaRON:null,
        valFinalaCuTvaRON:null,
        valFinalaFaraTvaEUR:null,
        valTvaEUR:null,
        valFinalaCuTvaEUR:null,
        valFinalaFaraTvaHUF:null,
        valTvaHUF:null,
        valFinalaCuTvaHUF:null,
        idValuta:'RON',
        ComandaClient:'',
        slidAdresaLivrare:0,
        val_discount_linii_ron:0,
        val_discount_oferta_ron:0,
        val_discount_linii_eur:0,
        val_discount_oferta_eur:0,
        val_discount_linii_huf:0,
        val_discount_oferta_huf:0,
        invoices:null,
        observatii_asm:'',
        reoffers_count:0
    };
    
    public dialogAdresa=false;
    public dialogTransitionShow ='';
    public dialogTransitionHide ='';
    public inputFreeTextComments='';
    public selectedAdresa:TAdresaLivrare={
        slid:'0',
        appid: '',
        cif: '',
        adresaCodJudet: '',
        adresaLocalitate: '',
        adresaAdresa: '',
        adresaCodPostal: 'x',
        tipAdresa: 'livrare',
        codTara: '',
        navisionid: '',
        denJudet: '',
        isNewAddress: '0'
    }
    public adresa:TAdresaLivrare={
        slid: '',
        appid: '',
        cif: '',
        adresaCodJudet: '',
        adresaLocalitate: '',
        adresaAdresa: '',
        adresaCodPostal: 'x',
        tipAdresa: 'livrare',
        codTara: '',
        navisionid: '',
        denJudet: '',
        isNewAddress: '0'
    };
    public products:TProductBasketMarkedFavorites[]=[];
    declare public $refs: any;
    public appidToBeTogglesWithFavorites = '';
    public userStore = getModule(user);
    public storeNomenclatoare = getModule(nomenclatoare);
    public storeFavorites = getModule(favorites);
    public urlToJPG = CONFIG_ENV.URL_CATEGORY.getJPG;
    public EventBusStore = getModule(eventbus);
    public changedItemsInOffer = false;
    public isAdreseLivrareActivated=false;

    private timeUntilFutureDate(pStringDate: string, pformat:string){
        return timeUntilFutureDate(pStringDate,pformat)
    }

    public humanStatusOffer(pstatus:string){
        let result='';
        switch (pstatus) {
            case 'o':
                result = this.$t('message.offer_received');
                break;
            case 'c':
                result = this.$t('message.canceled');
                break;
            case 'p':
                result = this.$t('message.request_sent');
                break;
            case 'k':
                result = this.$t('message.order_sent');
                break;
            case 'a':
                result = this.$t('message.order_accepted');
                break;
            case 'm':
                result = this.$t('message.order_modified');
                break;
            default:
                result = "error";
        }
    
        return result;
    }



    public toggleArticleInFavorites(item:TProductBasketMarkedFavorites,indexInProductsList:number) {
        const vueInst = this;
        console.log('item=%o',item)
        vueInst.appidToBeTogglesWithFavorites=item.appid;
        if (item.isInFavorite) {
            ServiceFavorites.removeProductFromFavorites(item.productCode).then(response=>{
                if(response.status=='success'){
                    vueInst.storeFavorites.remove_product_from_favorites(item);
                    vueInst.appidToBeTogglesWithFavorites='';
                    vueInst.products[indexInProductsList].isInFavorite=vueInst.storeFavorites.favorites.some(favorite=>{return favorite.productCode==item.productCode})
                    vueInst.$q.notify({
                        color: 'red-6',
                        textColor: 'white',
                        icon: 'favorite',
                        position: 'top',
                        timeout: 500,
                        message: this.$t('message.article_removed_from_favorite')
                    })
                }
            });
        } else {
            ServiceFavorites.identifyArticleInDBAndPutIntoFavorites(item.productCode, item.l, item.w, item.t, item.d, item.h, item.a, item.k, item.um1, item.um2, item.qUm1, item.qUm2, item.dorescDebitare, item.observatii)
                .then(response => {
                    vueInst.appidToBeTogglesWithFavorites = '';
                    if (response.status == 'success') {
                        const productForFavorite: TProductBasket = {
                            appid: response.appid,
                            productPid: response.productPid,
                            categoryName: response.categoryName,
                            categoryPid: response.categoryPid,
                            productCode: response.productCode,
                            productNameRo: response.productNameRo,
                            productNameEn: response.productNameEn,
                            productNameHu: response.productNameHu,
                            productNameDe: response.productNameDe,
                            qBuc: item.qBuc,
                            q_um_base: item.qBuc,
                            qUm1: item.qUm1,
                            qUm2: item.qUm2,
                            um1:item.um1,
                            um2:item.um2,
                            um1_to_um2:item.um1_to_um2,
                            l: item.l,
                            w: item.w,
                            t: item.t,
                            d: item.d,
                            h: item.h,
                            a: item.a,
                            k: item.k,
                            e: item.e,
                            dorescDebitare: item.dorescDebitare,
                            cuttingLength: item.cuttingLength,
                            cuttingWidth: item.cuttingWidth,
                            enumPlacaBara: item.enumPlacaBara,
                            densitate: item.densitate,
                            observatii:item.observatii,
                            nr_ord: item.nr_ord,
                            tip_um: item.tip_um,
                            price_eur: item.price_eur,
                            price_huf: item.price_huf
                        }
                        vueInst.storeFavorites.push_product_to_favorites(productForFavorite);
                        vueInst.products[indexInProductsList].isInFavorite=vueInst.storeFavorites.favorites.some(favorite=>{return favorite.productCode==item.productCode})
                        vueInst.$q.notify({
                            color: 'red-6',
                            textColor: 'white',
                            icon: 'favorite',
                            position: 'top',
                            timeout: 500,
                            message: this.$t('message.article_added_to_favorite')
                        })
                    }
                })
        }
    }

    public trySendCerereForAnOffer(){
        const vueInst=this;
        if(vueInst.products.length==0){
            vueInst.$q.notify({
                color: 'orange-9',
                textColor: 'white',
                icon: 'shopping_cart',
                position: 'top',
                timeout: 1000,
                message: this.$t('message.no_articles_in_request')
            })
            return false;
        }
        if(vueInst.slidAdresaLivrare.toString().length==0){
            vueInst.$q.notify({
                color: 'orange-9',
                textColor: 'white',
                icon: 'shopping_cart',
                position: 'top',
                timeout: 1000,
                message: this.$t('message.insert_delivery_address')
            })
            return false;
        }
        vueInst.setSelectedAdresa(vueInst.slidAdresaLivrare);
        vueInst.$q.loading.show();
        vueInst.sendCerereForAnOffer();
    }

    public removeArticle(item:TProductBasketMarkedFavorites,index:number) {
        const vueInst=this;
        vueInst.$q.dialog({
            title: this.$t('message.delete_item') + this.$i18n.locale == 'de' ? vueInst.products[index].productNameDe : this.$i18n.locale == 'ro' ? vueInst.products[index].productNameRo :  this.$i18n.locale == 'en' ? vueInst.products[index].productNameEn :
                                    vueInst.products[index].productNameHu,
            message: this.$t('message.delete_item_question'),
            cancel: true
        })
        .onOk(() => {
            vueInst.products.splice(index,1);
        });
    }

    public sendCerereForAnOffer(){
        const vueInst=this;
        ServiceOffer.sendBasketForAnOffer(vueInst.products,vueInst.nrComandaCerere,vueInst.termenCerere,vueInst.slidAdresaLivrare, vueInst.inputFreeTextComments).then(response=>{
            vueInst.$q.loading.hide();
            if(response.status=='success') {
                vueInst.EventBusStore.set_event({name:'eventCloseDialogViewOffer',params:{offerId:response.offerId}});
                vueInst.$router.push({name: 'FirstPageAfterPushOffer',  params: { pidOffer:response.offerId }});
                ServiceMail.sendMailNewRequest(response.offerId);
            }
        }).catch((error) => {
            vueInst.$q.loading.hide();
            vueInst.$q.notify({
                color: 'red',
                textColor: 'white',
                type: 'negative',
                icon: 'error',
                position: 'top',
                timeout: 3000,
                message: error.toString()
            })
        });
    }

    public moveUP(index: number){
        const vueInst=this;
        if (index > 0) {
            const currentItem = vueInst.products[index];
            currentItem.nr_ord = index-1;
            const newItem = vueInst.products[index-1];
            newItem.nr_ord = index;
            vueInst.products.splice(index, 1,newItem);
            vueInst.products.splice(index-1, 1, currentItem);
        }
    }

    public moveDown(index: number){
        const vueInst=this;
        if (index < vueInst.products.length-1) {
            const currentItem = vueInst.products[index];
            currentItem.nr_ord = index+1;
            const newItem = vueInst.products[index+1];
            newItem.nr_ord = index;
            vueInst.products.splice(index, 1,newItem);
            vueInst.products.splice(index+1, 1, currentItem);
        }
    }


    public getDetaliiCerere(){
        const vueInst=this;
        vueInst.products=[];
        ServiceCerere.getCerere(vueInst.propOfferId).then(response=>{
            if(response.status=='success'){
                //extend(true, vueInst.offerHeader,  response.offerHeader);
                vueInst.offerHeader=JSON.parse(JSON.stringify(response.offerHeader));
                vueInst.products=JSON.parse(JSON.stringify(response.products));
                vueInst.$q.loading.hide();
                vueInst.nrComandaCerere = vueInst.offerHeader.offerId;
                vueInst.nrComandaCerereInitial = vueInst.nrComandaCerere;
                vueInst.termenCerere = vueInst.offerHeader.termenLivrareSolicitat;
                vueInst.inputFreeTextComments = vueInst.offerHeader.observatii_asm ? vueInst.offerHeader.observatii_asm : '';
                ServiceAdreseLivrare.getAdresaLivrare(vueInst.offerHeader.slidAdresaLivrare).then (response=>{
                    if(response.status=='success') {
                        vueInst.adresa = JSON.parse(JSON.stringify(response.adresa))[0];
                        vueInst.slidAdresaLivrare = vueInst.adresa.slid;
                    }
                })
            }
        })
    }

    public optionsDataViitor (date:string) {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();

        const todayFmt = yyyy + '/' + mm + '/' + dd;
        return date > todayFmt
    }


    public onEditItem(item:TProductBasket, index:number) {
        const vueInst=this;
        console.log('edit item=%o',item)
        const prodcut_to_replace = vueInst.products[index];
        
        vueInst.changedItemsInOffer = prodcut_to_replace.qUm1 == item.qUm1 || prodcut_to_replace.qUm2 == item.qUm2 || prodcut_to_replace.qBuc == item.qBuc || 
                                      prodcut_to_replace.cuttingLength == item.cuttingLength || prodcut_to_replace.cuttingWidth == item.cuttingWidth;
                                      
        vueInst.products.splice(index,1, {...item, isInFavorite:vueInst.storeFavorites.favorites.some(favorite=>{return favorite.productCode==item.productCode})});
        console.log('Now, products=%o',vueInst.products)
    }

    private browseArticle(){
        const vueInst=this;
        vueInst.showBrowseArticles=true;
    }

    public repetaCerere(){
        const vueInst = this;
        if(!vueInst.changedItemsInOffer) {
            // vueInst.nrComandaCerere = '';
            // vueInst.termenCerere = '';
            // vueInst.slidAdresaLivrare = '';
            // vueInst.inputFreeTextComments = '';
            vueInst.changedItemsInOffer = true;
        }
    }

    @Watch('EventBusStore.event.eventId')
    onEventIdChanged() {
        const vueInst=this;
        console.log(vueInst.EventBusStore.event.name);
        if(vueInst.EventBusStore.event.name=='eventBrowseCategories'){
            if(vueInst.EventBusStore.event.params && vueInst.EventBusStore.event.params.pid){
                vueInst.pidForBrowseCategory= vueInst.EventBusStore.event.params.pid;
                vueInst.pidForBrowseCategoryHasArticles=false;
                console.log(vueInst.pidForBrowseCategory);
            }
        }

        if(vueInst.EventBusStore.event.name=='eventBrowseArticles'){
            if(vueInst.EventBusStore.event.params && vueInst.EventBusStore.event.params.pidCategory){
                vueInst.pidForBrowseCategory= vueInst.EventBusStore.event.params.pidCategory;
                vueInst.productPid = vueInst.EventBusStore.event.params.productPid;
                vueInst.code = vueInst.EventBusStore.event.params.code;
                
                vueInst.pidForBrowseCategoryHasArticles=true;
                console.log(vueInst.pidForBrowseCategory);
            }
        }

        if(vueInst.EventBusStore.event.name=='eventPutArticleInCerere'){
            if(vueInst.EventBusStore.event.params && vueInst.EventBusStore.event.params.artPentruCerere){
                console.log('vueInst.EventBusStore?.event?.params?.artPentruCerere=%o',)
                const artPentruCerere = JSON.parse(vueInst.EventBusStore.event.params.artPentruCerere);
                vueInst.products.push(artPentruCerere);
                vueInst.showBrowseArticles=false;
                vueInst.pidForBrowseCategory='0';
                vueInst.pidForBrowseCategoryHasArticles=false;
            }
        }

        if(vueInst.EventBusStore.event.name=='closeCurrentView'){
                if(vueInst.showBrowseArticles){
                    vueInst.showBrowseArticles=false;
                    vueInst.pidForBrowseCategory='0';
                    vueInst.pidForBrowseCategoryHasArticles=false;
                }else{
                    vueInst.EventBusStore.set_event({name:'eventCloseDialogViewOffer',params:null});
                }
        }

        if(vueInst.EventBusStore.event.name=='eventClickAddBtn'){
            if(vueInst.isAdreseLivrareActivated){
                vueInst.onOpenFormAddNewAdresa();
            } else {
                vueInst.closeFormEditAdresaLivrare(true);
            }
        }
    }

    public onOpenFormAddNewAdresa(){
        //this.selectedUser=puser
        this.selectedAdresa={
            slid:'0',
            appid: '',
            cif: '',
            adresaCodJudet: '',
            adresaLocalitate: '',
            adresaAdresa: '',
            adresaCodPostal: 'x',
            tipAdresa: 'livrare',
            codTara: '',
            navisionid: '',
            denJudet: '',
            isNewAddress: '1'
        };
        if(this.$q.platform.is.mobile){
            this.dialogTransitionShow ='slide-right';
            this.dialogTransitionHide ='slide-left';
        }else{
            this.dialogTransitionShow ='slide-right';
            this.dialogTransitionHide ='slide-left';
        }
        this.dialogAdresa=true;
    }

    public closeFormEditAdresaLivrare(needRefresh:boolean){
        const vueInst=this;
        vueInst.dialogAdresa=false;
        if(needRefresh){
            vueInst.getAdreseLivrare();
        }
    }

    public getAdreseLivrare(){
        const vueInst=this;
        ServiceAdreseLivrare.getAdreseLivrare().then(response=>{
            if(response.status=='success'){
                vueInst.adrese=JSON.parse(JSON.stringify(response.adrese));
                if(vueInst.adrese.length==1){
                    vueInst.slidAdresaLivrare=vueInst.adrese[0].slid;
                }
            }
        });
    }
    
    public setSelectedAdresa(slid: string){
        const vueInst = this;
        vueInst.adrese.forEach(adresa => {
            if(adresa.slid == slid) {
                vueInst.selectedAdresa = adresa;
            }
        });
        console.log(vueInst.selectedAdresa);
    }

    public downloadPDF() {
        const vueInst = this;
      
    }

    public downloadExcel() {
        const vueInst=this;
        ServiceDownload.generareExcelRequest(vueInst.offerHeader.offerId).then(responce => {
            if(responce.status == 'success') {
                const linkSource = responce.link;
                const downloadLink = document.createElement("a");
                const fileName = 'Request_'+vueInst.offerHeader.offerId+'.xlsx';
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
                // ServiceDownload.deleteFile(responce.filePath);
            } else {
                vueInst.$q.notify({
                    color: 'blue',
                    textColor: 'white',
                    type: 'warn',
                    icon: 'error',
                    position: 'top',
                    timeout: 3000,
                    message: responce.message
                })
            }
        });
    }


    public created(){
        const vueInst=this;
        vueInst.products=[];
        if(this.propOfferId){
            vueInst.$q.loading.show();
            //extend(true, this.adresa,  this.propAdresa);
            this.getDetaliiCerere();
            vueInst.getAdreseLivrare();
        }
    }
}
